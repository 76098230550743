import '@src/css/main.pcss';
import '@src/css/less/styles.less';
import './priorityNav.js';
import './script.js';

$('.links-container').scroll(function() {
  var currentScroll = $(this).scrollTop();

  console.log('test');

  if( currentScroll >= '50') {
    $('.links-container').addClass('hide-pseudo');
  } else {
    $('.links-container').removeClass('hide-pseudo');
  }
})
