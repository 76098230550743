var condensed = false;

$(window).scroll(function(){
	var currentScroll = $(window).scrollTop(),
	headerHeight = $('main > header').outerHeight();

	if (currentScroll >= headerHeight && !condensed) {
		$('body').addClass('nav-condensed');
		condensed = true;
	} else if (currentScroll < headerHeight && condensed) {
		$('body').removeClass('nav-condensed');
		condensed = false;
	}
});

// Mobile nav submenu dropdown
$('.nav-mobile .dropdown-trigger').click(function(e){

	// When the trigger is clicked, set height
	var dropdownInnerHeight = $(this).siblings('.dropdown').children().outerHeight() + 'px';

	$(this).siblings('.dropdown').css('height', dropdownInnerHeight);

	if( !$(this).hasClass('open') ) { // Dropdown is closed, dropdown is opening
		$(this).addClass('open').siblings('.dropdown').addClass('visible');

		$(this).delay(550).queue(function(){
			$(this).siblings('.dropdown').css('height', '');
			$(this).dequeue();
		});
	}
	else { // Dropdown is open, dropdown is closing
		$(this).removeClass('open').siblings('.dropdown').removeClass('visible');

		$(this).delay(1).queue(function(){
			$(this).siblings('.dropdown').css('height', '');
			$(this).dequeue();
		});
	}
});

// Mobile navigation close
$('.nav-mobile #close-trigger').click(function(e){
	e.preventDefault();

	$('body').removeClass('mobile-nav-open');
});

$('.nav-primary #nav-mobile-trigger').click(function(e){
	e.preventDefault();

	$('body').addClass('mobile-nav-open');
});

$('.testimonials > .testimonial').hover(function(){
	console.log('function begin');
	$(this).addClass('active').siblings().removeClass('active');
	console.log('function works');
});

$('.tabs > li').click(function(e){
	e.preventDefault();

	// Set tab container height to what it currently is
	var currentHeight = $(this).parents('.tabs-container').find('.tab-contents > .active').outerHeight();
	console.log('old height' + currentHeight);

	$(this).parents('.tabs-container').find('.tab-contents').css('height', currentHeight);

	// Get index of current item, to match to contents
	var index = $(this).index();

	// Height
	var $tabContents = $(this).parents('.tabs-container').find('.tab-contents > li:eq(' + index + ')'),
		tabInnerHeight = $tabContents.children().outerHeight();

	// Remove active from siblings, add it to (this)
	$(this).addClass('active').siblings().removeClass('active');

	// Add class active to matching contents, remove it from siblings
	$tabContents.parent().css('height', tabInnerHeight + 'px').delay(500).queue(function(){
		$(this).css('height', '').dequeue();
	});

	$tabContents.addClass('active').siblings().removeClass('active');
});

// Automatically trigger scroll on load
$(window).scroll();

$('.accordion .title').click(function(e){
	e.preventDefault();

	var contentsInnerHeight = $(this).parents('li').find('.contents-inner').outerHeight();
	$(this).parents('li').find('.contents').css('height', contentsInnerHeight + 'px').delay(1).queue(function(){
		$(this).css('height', '').dequeue();
	});

	if ($(this).parents('li').hasClass('active')) {
		$(this).parents('li').removeClass('active');
	}
	else {
		$(this).parents('li').addClass('active');
	}
});

// Trigger priorityNav on main navigation
$('.nav-primary > .nav-links').prioritize({breakpoint: 900});
